@use "variables";
@import "mixins";

.section-contact-main {
  background: #eae9e5;

  @include mobile {
  }

  @include desktop {
    padding-top: 50px;
    padding-bottom: 100px;

    .main-title {
      font-family: Avenir-Regular;
      font-weight: bold;
      font-size: 25px;
      color: #449841;
      text-align: center;
      z-index: 1;
      margin-bottom: 100px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
    }

    .title {
      font-family: Avenir-Regular;
      font-weight: bold;
      font-size: 20px;
      color: #449841;
      text-align: center;
      z-index: 1;
    }
  }
}
