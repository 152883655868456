@use "variables";
@import "mixins";

.section-main {
  background: #eae9e5;
  width: 100%;

  @include mobile {
    margin-bottom: 40px;
  }

  @include desktop {
    margin-bottom: 50px;
  }
}
