@use "variables";
@import "mixins";

.section-tagline-main {
  height: 83vh;
  background: inherit !important;

  .top-container {
    height: 60%;

    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;

    .img-machine {
      margin-top: 100px;
      height: 300px;
    }

    .right-section {
      padding-left: 100px;
      z-index: 1;

      .title1 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 20px;
        // color: #a78641;
        color: #449841;
      }

      .title2 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 40px;
        // color: #449841;
        color: #449841;
        margin-top: 20px;
      }
    }
  }

  .bottom-container {
    background-color: #1d1d1d41;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
