@use "variables";
@import "mixins";

.section-products-main {
  background: #eae9e5;

  @include mobile {
  }

  @include desktop {
    min-height: 97vh;
    padding-top: 50px;

    .title {
      font-family: Avenir-Regular;
      font-weight: bold;
      font-size: 25px;
      color: #449841;
      text-align: center;
      z-index: 1;
      margin-bottom: 50px;
    }

    .product-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;

      .left-section {
        .img-product {
          height: 300px;
          margin-right: 200px;
        }
      }
      .right-section {
        .product-name {
          font-size: 30px;
          font-weight: 200;
          margin-right: 20px;
          color: #5da727;
        }
        .product-capacity {
          font-size: 22px;
          font-weight: 200;
          margin-right: 20px;
          color: black;
        }
        .product-dimensions {
          font-size: 22px;
          font-weight: 200;
          margin-right: 20px;
          color: black;
        }
        .product-powerRating {
          font-size: 22px;
          font-weight: 200;
          margin-right: 20px;
          color: black;
        }
      }
    }
  }
}
