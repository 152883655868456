@use "variables";
@import "mixins";

.section-commercial-main {
  height: 83vh;
  background: inherit !important;

  .top-container {
    height: 60%;

    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;

    .img-machine {
      margin-top: 100px;
      height: 300px;
    }

    .right-section {
      padding-left: 100px;
      z-index: 1;
      background: #00000042;
      padding: 20px;
      border-radius: 20px;

      .title1 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 20px;
        color: #a78641;
      }

      .title2 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 30px;
        color: #449841;
        margin-top: 20px;
      }
    }

    .view-btn {
      border-radius: 5px;
      padding: 15px;
      font-size: 15px;
      background: rgb(255 255 255 / 0%);
      border: 1px solid white;
      color: white;
      transition: 0.4s;
      cursor: pointer;
      margin-top: 30px;
      float: right;

      &:hover {
        border: 2pt solid black;
        color: black;
        transition: 0.4s;
      }
    }
  }

  .bottom-container {
    background-color: #1d1d1d41;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
