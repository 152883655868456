@use "variables";
@import "mixins";

body {
  font-family: TitiliumWeb;
  user-select: none;

  a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  a:hover {
    color: variables.$color-product-green;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

@include mobile {
  .app-main {
    margin-top: 78px;
    margin-bottom: 40px;
  }
}

@include desktop {
  // html {
  //   scroll-behavior: smooth;
  //   scroll-snap-type: mandatory;
  //   scroll-snap-type: y mandatory;
  // }

  .app-main {
    margin-top: 91px;
    margin-bottom: 40px;
  }

  section {
    scroll-snap-align: start;
  }
}

section {
  position: relative;
}
