@use "variables";
@import "mixins";

.section-applications-main {
  height: 83vh;
  background: inherit !important;

  .top-container {
    height: 80%;
    justify-content: center;

    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;

    .img-machine {
      margin-top: 100px;
      height: 300px;
    }

    .right-section {
      z-index: 1;

      .title1 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 25px;
        color: #449841;
        text-align: center;
      }

      .title2 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 20px;
        color: white;
        margin-top: 20px;
      }

      .para {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-left: 20px;
      }
    }

    .img-leaf {
      height: 30px;
      width: 30px;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
    }

    .img-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 50px;

      p {
        font-family: TitiliumWeb-SemiBold;
        font-size: 16px;
        margin-top: 20px;
        color: white;
        background: #0000005c;
        padding: 4px;
        padding-left: 11px;
        padding-right: 11px;
        border-radius: 8px;
      }
    }

    img {
      height: 70px;
    }
  }

  .bottom-container {
    background-color: #1d1d1d41;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    .single-highlight-text {
      color: white;
      font-family: Avenir-Regular;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
