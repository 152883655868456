@use "variables";
@import "mixins";

.section-clients-main {
  background: #ffffff;

  @include mobile {
    .title {
      font-family: Avenir-Regular;
      font-weight: bold;
      font-size: 20px;
      color: #449841;
      text-align: center;
      padding-top: 120px;
    }

    .img-container {
      display: inline-block;
    }

    .img-client {
      margin: 25px;
      max-width: 70px;
    }

    .clients-container {
      margin-top: 30px;
      // max-height: 350px;
      // overflow: scroll;
    }

    .arrow-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 100px;

      svg {
        height: 50px;
        width: 50px;
      }
    }
  }

  @include desktop {
    min-height: 97vh;

    .clients-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-family: Avenir-Regular;
      font-weight: bold;
      font-size: 25px;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 40px;
      color: #449841;
    }

    .img-container {
      padding: 20px;
      display: inline-block;

      &:hover {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        border-radius: 15px;
        transition: 0.5s;
      }
    }

    .img-client {
      height: 80px;
      transition: 0.5s;
    }
  }
}
