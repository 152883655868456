@font-face {
  font-family: TitiliumWeb-Light;
  src: local("TitiliumWeb"),
    url(./assets/fonts/Titillium/TitilliumWeb-Light.ttf) format("opentype");
}

@font-face {
  font-family: TitiliumWeb;
  src: local("TitiliumWeb"),
    url(./assets/fonts/Titillium/TitilliumWeb-Regular.ttf) format("opentype");
}

@font-face {
  font-family: TitiliumWeb-SemiBold;
  src: local("TitiliumWeb"),
    url(./assets/fonts/Titillium/TitilliumWeb-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: TitiliumWeb-Bold;
  src: local("TitiliumWeb"),
    url(./assets/fonts/Titillium/TitilliumWeb-Bold.ttf) format("opentype");
}

@font-face {
  font-family: Legend;
  src: local("Legend"), url(./assets/fonts/Test/legend.ttf) format("opentype");
}

@font-face {
  font-family: Campus;
  src: local("Campus"), url(./assets/fonts/Test/campus.ttf) format("opentype");
}

@font-face {
  font-family: Avenir-Regular;
  src: local("Avenir"),
    url(./assets/fonts/Avenir/Avenir-Regular.otf) format("opentype");
}
