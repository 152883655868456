@use "variables";
@import "mixins";

.section-profile-main {
  height: 83vh;
  background: inherit !important;

  .top-container {
    height: 80%;
    justify-content: center;

    .text-container {
      background: #00000061;
      padding: 1px;
      border-radius: 10px;
    }

    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;

    .img-machine {
      margin-top: 100px;
      height: 300px;
    }

    .right-section {
      padding-left: 100px;
      z-index: 1;

      .title1 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 25px;
        color: #449841;
        text-align: center;
        background: #00000061;
        padding: 1px;
        border-radius: 10px;
      }

      .title2 {
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 20px;
        color: white;
        margin-top: 20px;
      }

      .para {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-left: 20px;
      }
    }

    .img-leaf {
      height: 30px;
      width: 30px;
    }
  }

  .bottom-container {
    background-color: #1d1d1d41;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
