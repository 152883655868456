@use "variables";
@import "mixins";

.section-actual-main {
  height: 83vh;
  background: inherit !important;

  .top-container {
    height: 90%;
    justify-content: center;

    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;

    .img-machine {
      margin-top: 100px;
      height: 300px;
    }

    .right-section {
      z-index: 1;

      .title1 {
        margin-top: 70px;
        font-family: Avenir-Regular;
        font-weight: bold;
        font-size: 25px;
        color: #449841;
        margin-bottom: 30px;
      }
    }

    .view-btn {
      border-radius: 5px;
      padding: 15px;
      font-size: 15px;
      background: rgb(255 255 255 / 0%);
      border: 1px solid white;
      color: white;
      transition: 0.4s;
      cursor: pointer;
      margin-top: 30px;
      float: right;

      &:hover {
        border: 2pt solid black;
        color: black;
        transition: 0.4s;
      }
    }
  }

  .bottom-container {
    background-color: #1d1d1d41;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
